export { default as countriesFixture } from "./countries.fixture.json"
export { default as rangesFixture } from "./ranges.fixture.json"
export { default as weaponsFixture } from "./weapons.fixture.json"
export { default as weaponsCategoriesFixture } from "./weapons.categories.fixture.json"
export { default as weaponsConditionsFixture } from "./weapons.conditions.fixture.json"
export { default as clubsFixture } from "./clubs.fixture.json"
export { default as clubsMembersFixture } from "./clubs.members.fixture.json"
export { default as eventsFixture } from "./events.fixture.json"
export { default as eventsCategoriesFixture } from "./events.categories.fixture.json"
export { default as eventsDivisionsFixture } from "./events.divisions.fixture.json"
export { default as eventsContestantsFixture } from "./events.contestants.fixture.json"
