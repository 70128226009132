
import Vue from "vue"
import AppSidebar from "@/components/AppSidebar.vue"
import config from "@/app.config"

export default Vue.extend({
  name: "Nsu",

  components: {
    AppSidebar
  },

  created() {
    if(config.runtime === "web" || !window.ipc) return
    window.ipc.send("APP_STARTED")
    window.ipc.on("SET_UPDATE_STATUS", (e, status) => {
      switch(status.type) {
        case "UPDATE_CHECKING":
          this.$info(this.$t("updateChecking"))
          break
        case "UPDATE_AVAILABLE":
          this.$info(this.$t("updateAvailable"))
          break
        case "UPDATE_NOT_AVAILABLE":
          this.$info(this.$t("updateNotAvailable"))
          break
        case "UPDATE_ERROR":
          this.$error(this.$t("updateError"))
          this.$error(status.message)
          break
        case "UPDATE_DOWNLOADED":
          this.$success(this.$t("updateDownloaded"))
          break
      }
    })
  }
})
