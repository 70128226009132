export default {
  name: "",
  shortName: "",
  leaderFullName: "",
  emailAddress: "",
  phoneNumber: "",
  streetAddress: "",
  zipCode: "",
  area: "",
  country: "",
  websiteUrl: "",
  rangeId: ""
}
