export default {
  id: "",
  number: 0,
  stand: 0,
  day: "",
  time: "",
  hits: [
    { hit: 1, sum: 0 },
    { hit: 2, sum: 0 },
    { hit: 3, sum: 0 },
    { hit: 4, sum: 0 },
    { hit: 5, sum: 0 },
    { hit: 6, sum: 0 },
    { hit: 7, sum: 0 },
    { hit: 8, sum: 0 },
    { hit: 9, sum: 0 },
    { hit: 10, sum: 0 },
    { hit: 12, sum: 0 },
    { hit: 13, sum: 0 }
  ],
  total: 0,
  measurement: null,
  calibre: "",
  condition: "",
  weaponId: "",
  eventId: "",
  divisionId: "",
  clubMemberId: "",
  colour: ""
}
