
import Vue from "vue"

export default Vue.extend({
  name: "AppSidebar",

  data: () => ({
    isOpen: false
  }),

  watch: {
    isOpen(): void {
      localStorage.isOpen = this.isOpen
    }
  },

  created() {
    if(localStorage.isOpen === undefined) return
    this.isOpen = localStorage.isOpen === "true"
  },

  methods: {
    toggle(): void {
      this.isOpen = !this.isOpen
    },

    navTo(url): void {
      this.$router.push(url)
    }
  }
})
